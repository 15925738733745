import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PartnerRider from "./pages/PartnerRider";
import LandingPage from "./pages/LandingPage";
import PrivatePolicy from "./pages/PrivatePolicy";
import TermsService from "./pages/TermsService";

import "./index.css";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import DataDeletion from "./pages/DataDeletion";

const App = () => {
  return (
    <BrowserRouter>
      <div className=' max-w-[1400px] mx-auto'>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/partner-rider' element={<PartnerRider />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/terms-of-service' element={<TermsService />} />
          <Route path='/privacy-policy' element={<PrivatePolicy />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/data-deletion' element={<DataDeletion />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
