import React from "react";
import { Link } from "react-router-dom";
import { images } from "../Assets";
import { PiTiktokLogo } from "react-icons/pi";

const Footer = () => {
  return (
    <footer className='py-4 md:py-8 md:text-center w-full md:h-auto bg-[#F4F4F4]'>
      <div className='flex flex-col md:flex-row md:justify-center md:items-center p-4 md:p-8'>
        <div className='md:flex md:flex-col md:items-center md:gap-6 md:w-1/3'>
          <div className='md:text-center'>
            {/* <Link to='/' className="">
              <img
                src={images.nowpix}
                className='h-16 md:h-20 md:mx-auto'
                alt='nownow rider'
              />
            </Link> */}

            <ul className='flex items-center justify-center mt-5 gap-7'>
              <li className='list-none'>
                <Link to='https://www.instagram.com/nownowdispatch'>
                  <img
                    src={images.instagramLogo}
                    className='w-8 h-8'
                    alt='instagram'
                  />
                </Link>
              </li>
              <li className='list-none'>
                <Link to='https://www.twitter.com/nownowdispatch'>
                  <img src={images.twitter} className='w-8 h-8' alt='twitter' />
                </Link>
              </li>
              <li className='list-none'>
                <Link to='https://www.facebook.com/share/Mt28BKXFUZo7UmDn/?mibextid=LQQJ4d'>
                  <img
                    src={images.facebook}
                    className='w-8 h-8'
                    alt='facebook'
                  />
                </Link>
              </li>
              <li className='list-none'>
                <Link to='https://www.linkedin.com/company/nownow-dispatch/'>
                  <img
                    src={images.linkedin}
                    className='w-8 h-8'
                    alt='linkedin'
                  />
                </Link>
              </li>
              <li className='list-none'>
                <Link to='https://www.tiktok.com/@nownowdispatch?_t=8nOJAdJ7iTt&_r=1'>
                  <PiTiktokLogo
                    className='w-7 h-7 rounded-lg bg-[#6BA740] text-white'
                    alt='tiktok'
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='md:flex md:flex-col md:items-center md:w-2/3 md:mt-10 gap-10'>
          <div className='flex mt-5 md:flex-row justify-center items-center gap-10 md:gap-16'>
            <p className='font-semibold'>
              <Link to='/terms-of-service'>Terms of Service</Link>
            </p>
            <p className='font-semibold'>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </p>
          </div>
          <div className='flex justify-center items-center mt-4'>
            <p className='text-center text-base text-primaryBlack'>
              Copyright © {new Date().getFullYear()} NowNow Dispatch. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
