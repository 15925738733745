import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { images } from "../Assets";
import { Link } from "react-router-dom";

const DataDeletion = () => {
  return (
    <div>
      <NavBar />
      <div className='pt-[100px] px-4'>
        <div>
          <h1 className='text-left font-bold text-2xl md:text-4xl text-primaryBlack'>
            Data Deletion
            <span className='text-[#C3990F]'> Policy</span>
          </h1>
          <p className='text-lg font-semibold text-primaryBlack mt-5'>
            Last Updated: September 16, 2024
          </p>
        </div>
        <p>
          The NowNow Dispatch Rider App, Owned by Jesse Effa and NowNow Dispatch
          Company, at NowNow, we value your privacy and offer you the ability to
          delete your account and associated data whenever you choose. You can
          check our{" "}
          <Link
            to='/privacy-policy'
            className='text-[#C3990F] underline font-bold'
          >
            Privacy Policy Page
          </Link>
        </p>
        <h2 className='text-xl text-redOuter font-semibold mt-6'>
          Steps to Delete your account
        </h2>
        <p>1. Open our NowNow Dispatch Rider app.</p>
        <p>2. Log in to your account via our NowNow Dispatch Rider app.</p>
        <p>3. Go to Settings page. </p>
        <p>4. Select the Delete Account button </p>
        <p>
          5. Tap the Text "I understand the Implications, Delete my Account"{" "}
        </p>
        <p>6. Select the "Cofirm" Buttom</p>
        <p>7. Your account is succesfully Deleted.</p>
      </div>
      <div class='grid grid-cols-1 md:grid-cols-4 gap-6 mt-5 px-4'>
        <img src={images.nownow1} alt='delete-1' />
        <img src={images.nownow2} alt='delete-1' />
        <img src={images.nownow3} alt='delete-1' />
        <img src={images.nownow4} alt='delete-1' />
        <img src={images.nownow5} alt='delete-1' />
        <img src={images.nownow6} alt='delete-1' />
        <img src={images.nownow7} alt='delete-1' />
      </div>
      <div className='mt-5 p-4 space-y-2'>
        <p>
          For any assistance with account deletion, contact us at
          support@nownowdispatch.com.
        </p>
        <p>
          Upon account deletion, your personal information, including your
          email, profile, and preferences, will be permanently deleted. Thank
          you for using NowNow Dispatch!
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default DataDeletion;
