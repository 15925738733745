import React from "react";
import NavBar from "../components/NavBar";
import StoreButtons from "../components/StoreButtons";
import { images } from "../Assets";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className=''>
      <NavBar />

      <div className='p-4 md:p-8 w-full h-auto'>
        <div className='md:flex justify-center items-center mt-20 md:mt-10 p-4'>
          <div className=' md:w-[40rem] flex flex-col gap-3 md:gap-10 mb-5'>
            <h1 className='text-3xl md:text-5xl font-bold'>
              We are a part of your life.
            </h1>
            <span className='text-[#C3990F] text-3xl font-bold'>
              Errands & Delivery.
            </span>
            <p className='text-lg md:text-2xl font-medium'>
              We run 
            </p>
            <StoreButtons />
          </div>

          <div className='flex justify-center w-full md:w-[50%] '>
            <img
              src={images.HeroImage}
              className='w-full md:w-full h-auto md:h-[60%]'
              alt='hero'
            />
          </div>
        </div>

        <div className=''>
          <h2 className='text-center font-bold text-3xl md:text-5xl text-primaryBlack'>
            How It <span className='text-[#C3990F]'>Works</span>
          </h2>
          <p className='text-primaryBlack text-center text-base font-normal mt-4 md:w-[36rem] mx-auto leading-7'>
            As a NowNow Angel (Dispatch rider), We provide you with all the
            support you need to get started.
          </p>
          <div className='mt-10 mx-auto justify-evenly items-center gap-10 w-full flex flex-col md:flex-row'>
            <div className='max-w-[20rem] flex flex-col items-center text-center'>
              <div className='w-56 h-56 mb-4'>
                <img
                  src={images.downloadApp}
                  className='w-48 h-48 mx-auto'
                  alt='Download App'
                />
              </div>
              <div className='space-y-3'>
                <h4 className='text-2xl font-bold text-primaryBlack'>
                  Download the App
                </h4>
                <p className='w-auto text-base font-normal text-primaryBlack'>
                  Download the NowNow Dispatch app from Google Play or the App
                  Store and create an account.
                </p>
              </div>
            </div>

            <div className='max-w-[20rem] flex flex-col items-center text-center'>
              <div className='w-56 h-56 mb-4'>
                <img
                  src={images.setAccount}
                  className='w-48 h-48 mx-auto'
                  alt='Set Up Account'
                />
              </div>
              <div className='space-y-3'>
                <h4 className='text-2xl font-bold text-primaryBlack'>
                  Set Up your Account
                </h4>
                <p className='w-auto text-base font-normal text-primaryBlack'>
                  Upload your documents, get activated, and choose when to go
                  online.
                </p>
              </div>
            </div>

            <div className='max-w-[20rem] flex flex-col items-center text-center'>
              <div className='w-56 h-56 mb-4'>
                <img
                  src={images.getOrder}
                  className='w-48 h-48 mx-auto'
                  alt='Receive Orders'
                />
              </div>
              <div className='space-y-3'>
                <h4 className='text-2xl font-bold text-primaryBlack'>
                  Receive Orders
                </h4>
                <p className='w-auto text-base font-normal text-primaryBlack'>
                  Once activated, you can start receiving orders and withdraw
                  your earnings anytime you choose.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-10 md:flex justify-center items-center p-8 md:p-12'>
          <div className='order-2 md:order-1'>
            <img src={images.Group1} alt='african' />
          </div>

          <div className='order-1 md:order-2 mb-8 gap-4 md:gap-10 p-2 md:p-8 w-full flex flex-col md:w-[36rem]'>
            <h1 className='text-2xl md:text-5xl font-bold text-primaryBlack leading-tight md:leading-normal'>
              Hassle-Free <br />
              <span className='text-[#C3990F] '>Delivery</span>
            </h1>

            <p className='text-base font-normal text-primaryBlack'>
              Join the league of satisfied NowNans who have made NowNow Dispatch
              their go-to errand service. You too can place your order and track
              its progress in real time. Be it food, market runs or any errand
              at all. Our reliable Dispatch service means you won’t wait for too
              long to get your order delivered.
            </p>
          </div>
        </div>

        <div className=' sm:mt-32 relative flex flex-col md:flex-row gap-10'>
          <div className='flex flex-1 flex-col p-8 md:p-12 relative z-10'>
            <div className='mb-8'>
              <h1 className='text-2xl md:text-5xl font-bold text-primaryBlack leading-tight md:leading-normal'>
                Partner With NowNow <br />
                <span className='text-[#C3990F]'>Dispatch</span>
              </h1>
              <p className='w-full md:w-[35rem] text-base font-normal text-primaryBlack leading-tight md:leading-normal'>
                Ride the NowNow wave, partner with us and showcase your business
                to new customers that will buy from you now! It’s easy.
              </p>
            </div>
            <div className='relative h-auto w-full bg-white rounded-xl shadow-lg '>
              <div className='w-full rounded-xl'>
                <img
                  src={images.vendor}
                  className='rounded-t-lg w-full'
                  alt='vendor'
                />
                <div className='px-6 py-4'>
                  <div className='font-normal text-left text-2xl mb-2 text-primaryBlack'>
                    Run errands for us {""}
                  </div>
                  <p className='text-primaryBlack text-base'>
                    Partner with us and run errands for thousands of customers .
                  </p>
                  <div className='flex justify-end my-6'>
                    <Link to={"/contact-us"}>
                      <img src={images.Arrow1} alt='vendor' />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-1 p-8 md:p-12 md:mb-[12rem]'>
            <div className='relative h-auto w-full bg-white rounded-xl shadow-lg  mb-0 md:mb-10'>
              <div className='w-full rounded-xl overflow-hidden'>
                <img
                  src={images.riderpix}
                  className='rounded-t-lg w-full'
                  alt='rider'
                />
                <div className='px-6 py-4'>
                  <div className='font-normal text-left text-2xl mb-2 text-primaryBlack'>
                    Become a NowNow Angel. {""}
                  </div>
                  <p className='text-primaryBlack text-base'>
                    Looking for a side hustle or full time gig? Earn good money
                    delivering errands to NowNans.
                  </p>
                  <div className='flex justify-end my-6'>
                    <Link to={"/partner-rider"}>
                      <img src={images.Arrow1} alt='rider' />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex md:flex-row  justify-center items-center w-full md:h-[35rem] p-4 md:p-8 mb-5 relative'>
          <div className='absolute top-0 left-0 w-full h-full z-10'>
            <img
              src={images.dashboard1}
              className='absolute left-10 top-[-1rem] md:top-[4rem] md:left-40 w-32 md:w-64 h-[24rem] md:h-[36rem] object-cover md:object-contain'
              alt='dashboard'
            />
          </div>
          <div className='bg-[#C3990F] w-full h-56 md:h-[20rem] gap-10 rounded-2xl relative z-0 flex items-center justify-evenly'>
            <div className='w-full md:w-[34rem] ml-40 md:ml-0 gap-4 md:gap-10 p-2 md:p-4'>
              <h1 className=' text-sm md:text-4xl font-semibold md:font-bold text-primaryBlack'>
                Download our Mobile App and Earn as a Rider
              </h1>
              {/* <div className='flex flex-row gap-3 md:gap-5 mt-2 md:mt-4'>
                <img
                  src={images.frame282}
                  className='w-40 h-12 md:h-16 md:w-[20rem]'
                  alt='icon1'
                />
              </div> */}
              <div className='mt-4 ml-auto md:mt-6'>
                <StoreButtons />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
